import React from 'react';
import { Layout, Seo } from 'components';

// import page style
import './dosing-and-administration.scss';

const DosingPage = () => {
  return (
    <Layout indication="hcp" className="hcp-dosing-and-administration">
      <Seo pageTitle="Hcp Home" />
      <div className="body-content-container">
        <div className="body-content gutter-all">
          {/** Actual content goes here */}
          <h1>Dosing and Administration Page</h1>
        </div>
      </div>
    </Layout>
  );
};

export default DosingPage;
